.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader {
  border: 13px solid #f3f3f3; /* Light grey */
  border-top: 13px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

.loader-check-container {
  position: fixed;
  top: 50%;
  margin-top: -50px;
  width: 100%;
  height: 300px;
}

.loader-check {
  width: 130px;
  height: 130px;
  animation: heartbeat 1s;

  position: inherit;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

.loader-text {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes heartbeat {
  0% { transform: scale(0.75); }
  20% { transform: scale(1); }
  40% { transform: scale(1.25); }
  60% { transform: scale(1); }
  80% { transform: scale(.75); }
  100% { transform: scale(1); }
}
