///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

	#main {
		background-color: _palette(bg-alt);

		> * {
			border-top: solid 1px _palette(border);

			&:first-child {
				border-top: 0;
			}

			@include inner;
		}

		&.alt {
			background-color: transparent;
			border-bottom: solid 1px _palette(border);
		}
	}